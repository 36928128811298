<template>
  <div class="infinite-list" style="width: 100%">
    <div class="content-box mt-3 mb-3 pr">
      <div class="back-btn tac cp" @click="$router.go(-1)">
        <i class="iconfont icon-fanhui"></i>
        <div class="mt-2">返回</div>
      </div>
      <div class="bs p-3" style="width: 100%;background: #ffffff;min-height: 600px;">
        <div class="mt-2 pl-3 fwb uf uf-pj" style="color: #303133">
          <div>
            <span>全部商品</span>
            <span class="pl-1">{{ dataList.length }}</span>
          </div>
          <div>
            <el-button v-if="isDelete" type="text" @click="deleteHandle()" style="font-size: 14px;color: #F56C6C">确认删除</el-button>
            <el-button v-if="isDelete" type="text" @click="switchDelete()" style="font-size: 14px;color: #909399">取消</el-button>
            <el-button v-if="!isDelete" type="text" @click="switchDelete()" style="font-size: 14px;">管理购物车</el-button>
            <el-button type="text" @click="deleteAll()" style="font-size: 14px;color: #F56C6C">清空购物车</el-button>
          </div>
          <div class="uf uf-ac">
            <div class="f14 mr-3">已选商品<span class="ml-1 mr-1" style="color: red;font-size: 16px;font-weight: bold;">{{ count || 0 }}</span>件</div>
            <div class="f14 mr-3" v-if="!isDelete">
              <span>合计 (不含运费) ：</span>
              <span style="color: red">￥</span>
              <span style="color: red">{{ (totalPrice || 0).toFixed(2) }}</span>
            </div>
            <el-button type="danger" @click="settlement(dataListSelections)" :disabled="this.dataListSelections.length === 0">
              去结算
            </el-button>
          </div>
        </div>
        <template v-if="dataList.length > 0">
          <el-table
              ref="shoppingTable"
              :data="dataList"
              v-loading="dataListLoading"
              @select="selectionChangeHandle"
              :header-row-style="{'color': '#606266'}"
              style="width: 100%;">
            <el-table-column
                type="selection"
                width="80">
            </el-table-column>
            <el-table-column
                header-align="center"
                align="left"
                width="400"
                label="商品信息">
              <template slot-scope="scope">
                <div class="mt-1 uf">
                  <el-image :src="scope.row.logoUrl ? $cons.SERVER_PATH + scope.row.logoUrl : require('@/assets/img/zwtp.png')" alt="" class="cp" style="width: 100px;height: 100px;" @click="toDetail(scope.row)">
                    <div slot="error">
                      <div class="error-img">
                        {{scope.row.serviceName && scope.row.serviceName.length > 6 ? scope.row.serviceName.substr(0,5) + '...' : scope.row.serviceName}}
                      </div>
                    </div>
                  </el-image>
                  <div style="margin-left: 10px;">
                    <span class="cart-title" @click="toDetail(scope.row)">{{scope.row.serviceName}}</span>
                    <p>服务类别：{{scope.row.itemName}}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                header-align="left"
                align="left"
                width="320">
              <template slot-scope="scope">
                <div class="uf pt-1 pr-4" style="height: 100px">
                  <span style="color: #909399">套餐选择：{{scope.row.skuName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="price"
                header-align="left"
                align="left"
                width="100"
                label="单价">
              <template slot-scope="scope">
                <span class="fwb">￥{{ (scope.row.price || 0).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="count"
                header-align="center"
                align="center"
                width="160"
                label="数量">
              <template slot-scope="scope">
                <el-input-number size="mini" v-model="scope.row.count" :min="0" style="width: 80%" @change="updateCart(scope.row)"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
                prop="totalPrice"
                header-align="left"
                align="left"
                width="100"
                label="总金额">
              <template slot-scope="scope">
                <span class="fwb" style="color: #ff0000">￥{{ (scope.row.totalPrice || 0).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                header-align="left"
                align="left"
                label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-fixing mb-5" style="margin-top: 20px">
            <div class="bottom-box uf uf-ac uf-pj">
              <div style="display: inline; margin-left: 14px">
                <el-button v-if="isDelete" type="text" @click="deleteHandle()" style="font-size: 14px;color: #F56C6C">确认删除</el-button>
                <el-button v-if="isDelete" type="text" @click="switchDelete()" style="font-size: 14px;color: #909399">取消</el-button>
                <el-button v-if="!isDelete" type="text" @click="switchDelete()" style="font-size: 14px;">管理购物车</el-button>
                <el-button type="text" @click="deleteAll()" style="font-size: 14px;color: #F56C6C">清空购物车</el-button>
              </div>
              <div class="uf uf-ac">
                <div class="f14 mr-3">已选商品<span style="color: red;font-size: 16px;font-weight: bold;">{{ count || 0 }}</span>件</div>
                <div class="f14 mr-3" v-if="!isDelete">
                  <span style="margin-right: 0">合计 (不含运费) ：</span>
                  <span style="color: red;font-size: 16px;font-weight: bold;margin: 0;">￥</span>
                  <span style="color: red;font-size: 18px;font-weight: bold;margin: 0;">{{ (totalPrice || 0).toFixed(2) }}</span>
                </div>
                <el-button type="danger" @click="settlement(dataListSelections)" :disabled="this.dataListSelections.length === 0">
                  去结算
                </el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="empty">
            <div class="f16 fwb mb-3">您的购物车还是空的，赶紧行动吧！您可以：</div>
            <div class="f14 ml-2">
              <div class="uf uf-ac mb-1">
                逛逛<el-link type="primary" class="ml-2" @click="$router.push({name: 'index'})">首页</el-link>
              </div>
              <div class="uf uf-ac">
                看看<el-link type="primary" class="ml-2" @click="$router.push({name: 'collect'})">我的收藏夹</el-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shopping-cart',
  data() {
    return {
      //用户登录商品列表
      dataList: [],
      radio: '',
      //取出本地存储选择商品数据
      dataListLoading: false,
      dataListSelections: [],
      totalPrice: 0,
      count: 0,
      isDelete: false
    }
  },
  computed: {
    //存取用户登录状态
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      },
      set(val) {
        this.$store.commit('user/updateLoginStatus', val)
      },
    },
  },
  created() {
    this.shoppingCartList()
  },
  methods: {
    // 跳转产品详情页面
    toDetail(item) {
      this.$router.push({name: 'service', query: {id: item.serviceId}})
    },
    // 多选
    selectionChangeHandle(val, row) {
      if (this.isDelete) {
        this.dataListSelections = val
      } else {
        if (val.length > 1) {
          let oldRow = val.filter(i => i.id !== row.id)[0]
          this.$refs.shoppingTable.toggleRowSelection(oldRow)
        }
        if (val.length > 0) {
          this.dataListSelections = [{...row}]
          this.totalPrice = this.dataListSelections[0].totalPrice
          this.count = this.dataListSelections[0].count
        } else {
          this.dataListSelections = []
          this.totalPrice = 0
          this.count = 0
        }
      }
      this.$forceUpdate()
    },
    //获取购物车列表
    shoppingCartList() {
      this.dataListLoading = true
      if (this.loginStatus) {
        this.$http({
          url: this.$http.adornUrl(this.$api.SHOPPING_CART.LIST),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.list
            this.handleSelect()
          }
          this.dataListLoading = false
        })
      }
    },
    handleSelect () {
      this.$nextTick(() => {
        if (this.dataListSelections.length > 0) {
          let index = this.dataList.findIndex(i => i.id === this.dataListSelections[0].id)
          let row = this.dataList[index]
          this.$refs.shoppingTable.toggleRowSelection(row)
          this.totalPrice = row.totalPrice
          this.count = row.count
        } else {
          this.totalPrice = 0
          this.count = 0
        }
      })
    },
    //更新购物车信息
    updateCart(item) {
      this.$http({
        url: this.$http.adornUrl(this.$api.SHOPPING_CART.UPDATE),
        method: 'post',
        params: this.$http.adornParams({
          id: item.id,
          count: item.count
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.shoppingCartList()

        }
      })
    },
    switchDelete () {
      this.isDelete = !this.isDelete
      this.dataListSelections = []
      this.totalPrice = 0
      this.count = 0
      this.$refs.shoppingTable.clearSelection()
    },
    // 删除
    deleteHandle(id) {
      if (this.dataListSelections.length <= 0) {
        this.$message.warning('请至少选中一件商品')
        return
      }
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(this.$api.SHOPPING_CART.DELETE),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.switchDelete()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.shoppingCartList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    // 清空购物车
    deleteAll() {
      this.$confirm('确定清空购物车吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(this.$api.SHOPPING_CART.DELETE_ALL),
          method: 'post'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.shoppingCartList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    //结算跳转订单支付页面
    settlement() {
      if (this.dataListSelections.length > 1) {
        return this.$message.warning('仅支持单个订单支付')
      }
      if (this.dataListSelections.length === 1) {
        this.$router.push({
          name: 'payment',
          params: {
            info: {
              itemCode: this.dataListSelections[0].itemCode,
              itemName: this.dataListSelections[0].itemName,
              serviceId: this.dataListSelections[0].serviceId,
              serviceName: this.dataListSelections[0].serviceName,
              skuId: this.dataListSelections[0].skuId,
              price: this.dataListSelections[0].price,
              quantity: this.dataListSelections[0].count,
              totalSum: this.dataListSelections[0].totalPrice,
              difference: this.dataListSelections[0].skuName
            }
          }
        })
      }
      if (this.dataListSelections.length < 1) {
        return this.$message.warning('请选择一个商品')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.infinite-list ::v-deep .el-table__header th {
  font-weight: normal;
}

.infinite-list ::v-deep .el-table__header .has-gutter tr .el-table-column--selection .cell {
  padding-left: 14px;
}

.infinite-list ::v-deep .el-table__header .has-gutter tr .el-table-column--selection .cell .el-checkbox::after {
  color: #606266;
  padding-left: 5px;
  content: "选择";
}

.infinite-list ::v-deep .pay-btn .el-button--primary {
  border-radius: 0px;
  height: 55px;
  width: 120px;
}

.infinite-list ::v-deep .el-table__header th .el-checkbox__input {
  display: none;
}

.content-box {
  width: 1325px;
  margin: 20px auto;
  .bs {
    border-radius: 0 10px 10px;
  }
}

.all-options ::v-deep .el-radio__inner {
  border: 1px solid #a0a0a0;
}

.li-number .el-input-number {
  width: 100px;
}

/*底部固定栏*/
.bottom-fixing {
  width: 1268px;
  height: 55px;
  position: sticky;
  bottom: 0;
  z-index: 99;
  background-color: #f6f7f8;
}

.bottom-box {
  line-height: 55px;
}

.bottom-box span {
  margin: 0 10px;
  font-size: 14px;
}

.pay-btn {
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  background: #409EFF;
  color: #fff;
  cursor: pointer;
  letter-spacing: 2px;
}

.pay-btn:hover {
  opacity: 0.9;
}

.cart-title {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #303133;
}

.cart-title:hover {
  color: #f60;
  text-decoration: underline;
}

.empty {
  padding: 90px 0 100px 320px;
  background: url(~@/assets/img/shopping-cart.png) no-repeat 190px 85px;
  position: relative;
}
.error-img {
  background-image: url("../../assets/img/product/default_3.png");
  background-size: cover;
  width: 100px;
  height: 100px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 20px 10px;
  color: #FFFFFF;
}
</style>
